import React from 'react'
import ReactDOM from 'react-dom'
import { renderNotification } from '../utils/flash_notifications';
import "../channels/country_channel";

const AiPlaceDetailsGenerator = () => {
  const elements = document.querySelectorAll('.js-generate-place-details-with-ai')

  function handleClick(e) {
    e.preventDefault()
    renderNotification('Generating description...', 'success')
    
    fetch('/generate_place_details', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: JSON.stringify({ place_name: document.querySelector('.js-name-it').value })
    })
    .then(response => response.json())
    .then(data => {
      console.log('Success:', data);
      data.translations.forEach((translation, ind) => {
        document.querySelector(`.js-description-${translation.locale}`).value = translation.description;
        document.querySelector(`.js-name-${translation.locale}`).value = translation.name;
      });

      renderNotification("Description generated!", 'success')
    })
    .catch(error => console.error('Error:', error));
  }

  elements.forEach(element => {
    ReactDOM.render(
      <a onClick={(e) => handleClick(e)} className="button--success"> Generate with AI</a>,
      element
    )
  })
}

export default AiPlaceDetailsGenerator;
