import React from 'react'
import ReactDOM from 'react-dom'
import { renderNotification } from '../utils/flash_notifications';
import Modal from '../components/Modal';
import AiRegionSuggestionsContainer from '../components/AiRegionSuggestionsContainer';

const AiRegionSuggestionsGenerator = () => {
  const elements = document.querySelectorAll('.js-generate-place-regions-with-ai')
  const countryElement = document.getElementById('country');
  const countryId = countryElement.getAttribute('data-country-id');

  function renderModal(data) {
    ReactDOM.render(
      <Modal isOpen={true}>
        <AiRegionSuggestionsContainer data={data} countryId={countryId} />
      </Modal>,
      document.querySelector('.js-modal')
    )
  }
  function handleClick(e) {
    e.preventDefault()
    renderNotification('Generating region suggestions...', 'success')
    
    fetch('/generate_place_regions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: JSON.stringify({ country_id: countryId })
    })
    .then(response => response.json())
    .then(data => {
      console.log('Success:', data);
      renderModal(data)

      renderNotification("Suggestion for regions generated!", 'success')
    })
    .catch(error => console.error('Error:', error));
  }

  elements.forEach(element => {
    ReactDOM.render(
      <a onClick={(e) => handleClick(e)} className="button--success"> Generate place region suggestions</a>,
      element
    )
  })
}

export default AiRegionSuggestionsGenerator;
