import React from 'react'
import ReactDOM from 'react-dom'

import FlashNotification from '../components/FlashNotification';

export function renderNotification(message, type) {
  ReactDOM.render(
    <FlashNotification
      message={message}
      type={type}
    />,
    document.querySelector('.js-flash-notification')
  )
}