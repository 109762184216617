
import React, {useEffect, useState} from 'react'

const Modal = ({ isOpen, children }) => {
  const [visible, setVisible] = useState(isOpen);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setVisible(false);
      }
    };
    if (visible) {
      document.addEventListener("keydown", handleKeyDown);
    }
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [visible]);

  if (!visible) return null;

  return (
    <div className="modal-overlay" onClick={() => setVisible(false)}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="modal-close" onClick={() => setVisible(false)}>&times;</button>
        {children}
      </div>
    </div>
  );
};

export default Modal;