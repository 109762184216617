import React from 'react'

const FlashNotification = (props) => {
  return(
    <div className="alerts">
      <div className="alert__item">
        <div className="alert__icon">
          <div className="icon--big-info" data-icon="info"></div>
        </div>
        <div className="alert__content">
          <p>{props.message}</p>
          <a 
            className="alert__link js-close-alert"
            onClick={e => e.target.parentElement.parentElement.remove()}
          >Close message</a>
        </div>
      </div>
    </div>
  )
}

export default FlashNotification
