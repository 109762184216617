import React from 'react'

const ToggleTabsContainer = (props) => {  
  return(
    <div>
      <div className={`tab`}>
        {props.children}
      </div>
  </div>
  )
}

export default ToggleTabsContainer;