import React, { useState } from 'react'
import { renderNotification } from '../utils/flash_notifications';

const AiRegionSuggestionsContainer = (props) => {  
  const [selectedSuggestions, setSelectedSuggestions] = useState([]);
  const [savedSuggestions, setSavedSuggestions] = useState([]);
  
  if(!props.countryId) return;
  if(!props.data) return;

  const handleCheckboxChange = (suggestion, isChecked) => {
    if (isChecked) {
      setSelectedSuggestions([...selectedSuggestions, suggestion]);
    } else {
      setSelectedSuggestions(selectedSuggestions.filter(s => s !== suggestion));
    }
  };

  console.log('Selected suggestions:', selectedSuggestions);

  function saveSuggestions() { 
    console.log('Saving suggestions:', selectedSuggestions);
    renderNotification('Saving suggestions...', 'success') 
    fetch('/save_place_region_suggestions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: JSON.stringify({ country_id: props.countryId, region_suggestions: selectedSuggestions })
    })
    .then(response => response.json())
    .then(data => {
      console.log('Suggestions saved:', data);
      // Handle success
      renderNotification('Suggestions saved!', 'success')
      setSavedSuggestions(data.regions);
    })
    .catch(error => {
      console.error('Error saving suggestions:', error);
      // Handle error
    });
  }

  console.log({savedSuggestions})

  if(savedSuggestions.length > 0) {
    return (
    <div>
      <b>Suggestions saved successfully.</b>
      <br />
      <br />
      <ul>
        {savedSuggestions.map((suggestion, index) => (<li key={index}><a target="_blank" href={suggestion.url}>{suggestion.name}</a></li>))}
      </ul>
    </div>)
  }

  return(
    <div>
      <h2>Generated suggestions</h2>
      <br />
      <div>
        {props.data.region_suggestions.map((suggestion, index) => (
          <div key={index}>
            <span>
              <b>{suggestion.translations[0].name}</b>
              <input 
                type="checkbox"
                id={`custom-checkbox-${index}`}
                className="custom-checkbox"
                onChange={(e) => handleCheckboxChange(suggestion, e.target.checked)}
              />
            </span>
            <br />
          </div>
        ))}
      </div>

      <button className="button--success" onClick={saveSuggestions}>Save suggestions</button>
    </div>
  )
}

export default AiRegionSuggestionsContainer
