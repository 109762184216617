import consumer from "./consumer"

document.addEventListener('DOMContentLoaded', () => {
  const countryElement = document.getElementById('country');
  const countryId = countryElement.getAttribute('data-country-id');

  if(!countryId) return
  
  consumer.subscriptions.create({ country_id: countryId, channel: "CountryChannel" }, {
    connected() {
      console.log('Connected to CountryChannel');
      // Called when the subscription is ready for use on the server
    },

    disconnected() {
      console.log('Disconnected from CountryChannel');
      // Called when the subscription has been terminated by the server
    },

    received(data) {
      console.log('Received data:', data);
      // Called when there's incoming data on the websocket for this channel
    }
  });
});