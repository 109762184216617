import React from 'react'

const TabContent = (props) => {
  return(
    <div style={props.style} className='tab__content'>
      {props.children}
    </div>
  )
}

export default TabContent;