import React from 'react'

const TabContentItem = (props) => {
  return(
    <div id={`translation-${props.id}`} className={`tab__content__item`} key={props.index}>
      {props.children}
    </div>
  )
}

export default TabContentItem;