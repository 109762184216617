import React, { useState, useEffect } from 'react'
import { renderNotification } from '../utils/flash_notifications';
import ToggleTabs from '../behaviours/ToggleTabs'
import TabItemLink from './ToggleTabs/TabItemLink';
import TabContent from './ToggleTabs/TabContent';
import TabContentItem from './ToggleTabs/TabContentItem';
import ToggleTabsContainer from './ToggleTabs/ToggleTabsContainer';
import TabItems from './ToggleTabs/TabItems';

const AiPlaceSuggestionsContainer = (props) => {  
  const [allSugestions, setAllSuggestions] = useState(props.data);
  const [groupedSuggestions, setGroupedSuggestions] = useState([]);
  const [selectedSuggestions, setSelectedSuggestions] = useState([]);
  const [savedSuggestions, setSavedSuggestions] = useState([]);
  const [regions, setRegions] = useState([]);

  if(!props.countryId) return;
  if(!props.data) return;

  useEffect(() => {
    if (props.data) {
      ToggleTabs();
    }
  }, [props.data]);

  useEffect(() => {
    const selected = allSugestions.filter(s => s.selected);
    setSelectedSuggestions(selected);

    const grouped = allSugestions.reduce((acc, suggestion) => {
      const region = suggestion.region;
      if (!acc[region]) {
        acc[region] = [];
      }
      acc[region].push(suggestion);
      return acc;
    }, {});

    setGroupedSuggestions(grouped);
  }, [allSugestions]);


  const handleCheckboxChange = (suggestion, isChecked) => {
    const updatedSuggestions = allSugestions.map(s => {
      if (s.internal_id === suggestion.internal_id) {
        return { ...s, selected: isChecked };
      }
      return s;
    });
    setAllSuggestions(updatedSuggestions);
  };

  useEffect(() => {
    const rr = allSugestions.map(item => ({
      id: item.region_id,
      name: item.region
    }));

    setRegions(rr)
  },[])

  const handleRegion = (suggestion, target) => {
    const newRegionText = target.selectedOptions[0].text;
    const newRegionId = target.value

    const updatedSuggestions = allSugestions.map(s => {
      if (s.internal_id === suggestion.internal_id) {
        return { ...s, region_id: newRegionId, region: newRegionText };
      }
      return s;
    });
    setAllSuggestions(updatedSuggestions);
  };

  const handleTypeChange = (suggestion, newType) => {
    const updatedSuggestions = allSugestions.map(s => {
      if (s.internal_id === suggestion.internal_id) {
        return { ...s, type: newType };
      }
      return s;
    });
    setAllSuggestions(updatedSuggestions);
  };


  function saveSuggestions() { 
    console.log('Saving suggestions:', selectedSuggestions);
    renderNotification('Saving suggestions...', 'success') 
    fetch('/save_place_suggestions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: JSON.stringify({ country_id: props.countryId, suggestions: selectedSuggestions })
    })
    .then(response => response.json())
    .then(data => {
      console.log('Suggestions saved:', data);
      renderNotification('Suggestions saved!', 'success')
      setSavedSuggestions(data.places);
    })
    .catch(error => {
      console.error('Error saving suggestions:', error);
    });
  }

  function handleSuggestionTitle(suggestion, locale, event) {
    const translations = suggestion.translations
    const translationToBeUpdated = translations.find(tt => tt.locale == locale)
    translationToBeUpdated.name = event.target.value

    const updatedSuggestions = allSugestions.map(s => {
      if (s.internal_id === suggestion.internal_id) {
        return { ...s, translations: translations };
      }
      return s;
    });
    setAllSuggestions(updatedSuggestions);
  }

  function handleSuggestionDescription(suggestion, locale, event) {
    const translations = suggestion.translations
    const translationToBeUpdated = translations.find(tt => tt.locale == locale)
    translationToBeUpdated.description = event.target.value

    const updatedSuggestions = allSugestions.map(s => {
      if (s.internal_id === suggestion.internal_id) {
        return { ...s, translations: translations };
      }
      return s;
    });
    setAllSuggestions(updatedSuggestions);
  }

  console.log('All suggestions:', allSugestions);
  console.log('Selected suggestions:', selectedSuggestions);
  console.log('Grouped suggestions:', groupedSuggestions);

  if(savedSuggestions.length > 0) {
    return (
    <div>
      <b>Suggestions saved successfully.</b>
      <br />
      <br />
      <ul>
        {savedSuggestions.map((suggestion, index) => (<li key={index}><a target="_blank" href={suggestion.url}>{suggestion.name}</a></li>))}
      </ul>
    </div>)
  }

  return(
    <div>
      <ToggleTabsContainer>
          <TabItems>
            {allSugestions.map((suggestion, index) => (
              <TabItemLink
                key={index}
                index={index}
                id={suggestion.internal_id}
                text={suggestion.region}
              />
            ))}
          </TabItems>
          <TabContent style={{padding: '20px'}}>
            {allSugestions.map((suggestion, index) => (
              <TabContentItem
                key={index}
                id={suggestion.internal_id}
                index={index}
              >
                <div key={index}>
                    <span>
                      <b style={{marginRight: '8px', fontSize: '16px'}}>{suggestion.translations[0].name}</b>
                      <input 
                        type="checkbox"
                        id={`custom-checkbox-${index}`}
                        className="custom-checkbox"
                        onChange={(e) => handleCheckboxChange(suggestion, e.target.checked)}
                      />
                    </span>
                    <ToggleTabsContainer>
                      <TabItems>
                        {suggestion.translations.map((translation, innerIndex) => (
                          <TabItemLink
                            key={innerIndex}
                            index={innerIndex}
                            id={`${translation.locale}-${suggestion.internal_id}`}
                            text={translation.locale}
                          />
                        ))}
                      </TabItems>
    
                      <TabContent>
                        {suggestion.translations.map((translation, innerIndex) => (
                          <TabContentItem
                            key={innerIndex}
                            id={`${translation.locale}-${suggestion.internal_id}`}
                            index={innerIndex}
                          >
                            <input className='form__input' onChange={(e) => handleSuggestionTitle(suggestion, translation.locale, e)} value={translation.name} type="text" />
                            <textarea className='form__input' onChange={e => handleSuggestionDescription(suggestion, translation.locale, e)} value={translation.description}/>
                          </TabContentItem>
                        ))}
                      </TabContent>
                    </ToggleTabsContainer>

    
                    <span>Region</span>
                    <select
                      className='form__input'
                      value={suggestion.region_id}
                      onChange={(e) => handleRegion(suggestion, e.target)}
                    >
                      { regions.map((region, index) => <option key={index} value={region.id}>{region.name}</option>)}
                    </select>
                    
                    <span>Type</span>
                    <select
                      className='form__input'
                      value={suggestion.type}
                      onChange={(e) => handleTypeChange(suggestion, e.target.value)}
                    >
                      <option value="attraction">Attraction</option>
                      <option value="city">City</option>
                    </select>
    
                    <br />
                    <br />
                </div>
              </TabContentItem>
            ))}
          </TabContent>
        </ToggleTabsContainer>

      <button className="button--success" onClick={saveSuggestions}>Save suggestions</button>
    </div>
  )
}

export default AiPlaceSuggestionsContainer
