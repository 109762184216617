import React from 'react'

const TabItems = (props) => {  
  return(
    <div className='tab__item'>
      {props.children}
    </div>
  )
}

export default TabItems;