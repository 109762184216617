const hideContents = contents => {
  contents.forEach(content => {
    content.classList.remove('is-active')
  })
}

const handleTabLinkClick = (event, tab) => {
  event.preventDefault()
  const currentElement = event.target
  const parentTab = currentElement.closest('.tab')
  const currentRef = currentElement.getAttribute('href')
  const currentContent = document.querySelector(currentRef)

  const tabLinks = parentTab.querySelectorAll(':scope > .tab__item .tab__item__link')
  const tabContents = parentTab.querySelectorAll(':scope > .tab__content > .tab__content__item')

  hideContents(tabLinks)
  hideContents(tabContents)

  currentElement.classList.add('is-active')
  currentContent.classList.add('is-active')
}

const ToggleTabs = () => {
  const tabs = document.querySelectorAll('.tab')

  tabs.forEach(tab => {
    const tabItems = tab.querySelectorAll('.tab__item')
    const tabContents = tab.querySelectorAll('.tab__content')

    tabItems.forEach(item => {
      const tabLinks = item.querySelectorAll('.tab__item__link')

      if (tabLinks.length > 0) {
        tabLinks[0].classList.add('is-active')
      }

      tabLinks.forEach(link => {
        link.addEventListener('click', evt => {
          handleTabLinkClick(evt, tab)
        })
      })
    })

    tabContents.forEach(item => {
      const tabContentItems = item.querySelectorAll('.tab__content__item')

      if (tabContentItems.length > 0) {
        tabContentItems[0].classList.add('is-active')
      }
    })
  })
}

export default ToggleTabs
